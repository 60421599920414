import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback
} from 'react'
import ReactDOM from 'react-dom'
import 'whatwg-fetch'
import {
  __,
  always,
  isNil,
  unless,
  isEmpty,
  values,
  filter,
  compose,
  gt,
  sum,
  map,
  converge,
  multiply,
  identity,
  mergeWith,
  subtract,
  pick,
  prop,
  propOr,
  assoc,
  assocPath,
  o,
  sortBy,
  applySpec,
  find,
  both,
  contains,
  nth,
  unnest,
  pathOr,
  join,
  concat,
  groupBy,
  path,
  mapObjIndexed,
  toPairs,
  apply,
  evolve,
  nthArg,
  all,
  memoizeWith
} from 'ramda'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'
import MarkerClusterer from '@google/markerclusterer'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Search from '@material-ui/icons/Search'
import GpsFixed from '@material-ui/icons/GpsFixed'
import GpsNotFixed from '@material-ui/icons/GpsNotFixed'
import Check from '@material-ui/icons/Check'
import Link from 'components/Link'
import Modal from 'components/Modal'
import Button from 'components/Button'
import NavContext from 'components/NavProvider'
import ProductContext from 'components/ProductContext'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import StoryblokTileCallToAction from 'components/StoryblokTileCallToAction'
import useProduct from 'hooks/useProduct'
import { mediaQuery } from 'utils/style'
import m1 from 'images/m1.png'
import m2 from 'images/m2.png'
import m3 from 'images/m3.png'

const gtmPush = memoizeWith(
  (d, e) => `${d?.id}${e}`,
  (dealer, event) => () => {
    dataLayer.push({
      event,
      postCode: dealer.address.postCode,
      dealer: dealer.id
    })
  }
)

const meterPerDeg = (6378137 * 2 * Math.PI) / 360

const Table = styled.table`
  flex: 1;
  height: 100%;
  margin-left: 16px;
  text-align: center;
`
const Row = styled.tr``
const Col = styled.td`
  &:first-child {
    text-align: right;
  }
`

const Background = styled.div`
  && {
    padding-top: 5px;
  }
  background-color: white;
  z-index: 501;
`

const Inputs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Checkboxes = styled.div``

const Content = styled.div`
  padding: 16px;
  min-height: 230px;
  height: 100%;
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: ${({ align }) => align || 'flex-start'};
  ${mediaQuery()}
`

const MapContainer = styled.div`
  height: 540px;
  width: 100%;
`

const Container = styled(Grid)``

const StyledInputLabel = styled(InputLabel)`
  padding-right: 16px;
`

const Item = styled(Grid)`
  > * {
    background-color: ${({ theme }) => theme.colors[theme.primary].color};
    color: ${({ theme }) => theme.colors[theme.primary].contrast};
    .title {
      color: ${({ theme }) => theme.colors[theme.primary].highlight};
    }
  }
  :first-child {
    ${Content} {
      min-height: 170px;
      background-color: ${({ theme }) => theme.colors.black.color};
      color: ${({ theme }) => theme.colors.black.contrast};
      h1 {
        color: ${({ theme }) => theme.colors.black.highlight};
      }
      .subline,
      .roofline {
        color: ${({ theme }) => theme.colors.black.contrast};
      }
    }
  }
  :nth-child(2) {
    ${Content} {
      min-height: 170px;
    }
  }
`

const LocationButton = styled.button`
  background-color: white;
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin-right: 14px;
  padding: 4px;
`

const RadiusContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    width: 5rem;
  }
  p {
    word-break: keep-all;
  }
`

const Card = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  background-color: white;
  padding: 8px;
  height: 100%;
  color: black;
  width: 350px;
  > p {
    display: flex;
    justify-content: space-between;
    word-break: keep-all;
    :first-child {
      font-size: 20px;
      margin-bottom: 5px;
    }
    :
  }
  > a {
    margin: 3px;
  }
`

const parseGeocodeResult = o(
  applySpec({
    formData: {
      street: o(prop('long_name'), find(o(contains('route'), prop('types')))),
      city: o(
        prop('long_name'),
        find(
          o(both(contains('locality'), contains('political')), prop('types'))
        )
      ),
      postCode: o(
        prop('long_name'),
        find(o(contains('postal_code'), prop('types')))
      ),
      nr: o(
        prop('long_name'),
        find(o(contains('street_number'), prop('types')))
      )
    }
  }),
  pathOr({}, [0, 'address_components'])
)

export default function StoryblokLocator ({ data }) {
  const [dialogPhoneNumber, setDialogPhoneNumber] = useState(false)
  const [modalProps, setModalProps] = useState(false)
  const [location, setLocation] = useState({})
  const [
    {
 gmap, maps, geocoder, circle, controlDiv, createReverseGeocode 
},
    setMapRefs
  ] = useState({})
  const [stores, setStores] = useState()
  const [results, setResults] = useState([])
  const [radius, setRadius] = useState(Number(data.defaultRadius))
  const [filterObj, setFilter] = useState({})
  const searchInput = useRef()
  const cluster = useRef()
  const { history } = useContext(NavContext) || { history: [] }

  const historyContext = propOr({}, 'context', nth(-2, history))

  const product = useProduct(historyContext?._product)
  const productContext = useContext(ProductContext)

  const openPhoneDialog = memoizeWith(prop('id'), dealer => () => {
    gtmPush(dealer, 'dealer-call')
    setDialogPhoneNumber(path(['dealer', 'phone'], dealer))
  })

  const openModal = memoizeWith(prop('id'), dealer => () => {
    setModalProps({
      key: Math.random(),
      dealer,
      prefetch: location.geocode,
      formData: historyContext
    })
  })

  const closeModal = useCallback(() => {
    setModalProps(false)
  })

  const updateFilter = p => e =>
    setFilter(assocPath(p, e.target.checked, filterObj))

  historyContext.message = compose(
    unless(isEmpty, concat(data.messageTemplate)),
    join(','),
    values,
    map(
      p => `${p[0].component.group[0].component.title}: ${p[0].component.title}`
    ),
    pick(values(productContext[(product?.id)])),
    groupBy(prop('id')),
    unnest,
    map(
      converge(map, [
        o(
          assocPath(['component', 'group']),
          pathOr([{}], ['component', 'group'])
        ),
        pathOr([], ['component', 'properties'])
      ])
    )
  )(product?.component?.properties || [])

  const getLocation = useCallback(
    () =>
      createReverseGeocode
      && gmap
      && navigator.geolocation.getCurrentPosition((position) => {
        const loc = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        setLocation({
          gps: true,
          ...loc,
          geocode: createReverseGeocode(loc)
        })
        gmap.setCenter(loc)
      }),
    [gmap, createReverseGeocode]
  )

  useEffect(
    () => {
      if (!cluster.current && gmap && stores && maps) {
        cluster.current = new MarkerClusterer(
          gmap,
          map((store) => {
            const marker = new maps.Marker({
              position: store.location,
              map: gmap
            })
            maps.event.addDomListener(marker, 'click', () =>
              setLocation({
                lat: store.location.lat,
                lng: store.location.lng,
                gps: false,
                geocode: createReverseGeocode(store.location)
              })
            )
            return marker
          }, stores),
          {
            maxZoom: 11,
            zoomOnClick: false,
            styles: [
              { url: m1, height: 50, width: 50 },
              { url: m2, height: 60, width: 60 },
              { url: m3, height: 70, width: 70 }
            ],
            gridSize: 50
          }
        )
      }
    },
    [gmap, maps, stores]
  )

  const initMap = ({ maps: _maps, map: _map }) => {
    const createDiv = document.createElement('div')
    const _geocoder = new _maps.Geocoder()
    const _createReverseGeocode = loc => () =>
      new Promise(resolve =>
        _geocoder.geocode({ location: loc }, (result, status) =>
          resolve({ result, status })
        )
      ).then(({ result, status }) => {
        if (status === 'OK') {
          return { ...parseGeocodeResult(result), placesResult: result?.[0] }
        }
      })
    _map.addListener('click', ({ latLng }) => {
      setLocation({
        lat: latLng.lat(),
        lng: latLng.lng(),
        gps: false,
        geocode: _createReverseGeocode({
          lat: latLng.lat(),
          lng: latLng.lng()
        })
      })
    })
    _map.controls[_maps.ControlPosition.RIGHT_BOTTOM].push(createDiv)
    const _circle = new _maps.Circle({
      clickable: false,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: _map,
      center: pick(['lat', 'lng'], location),
      radius: radius * 1000
    })
    const _autocomplete = new _maps.places.Autocomplete(searchInput.current, {
      fields: ['geometry.location'],
      ...(data.countryKey && {
        componentRestrictions: {
          country: data.countryKey
        }
      })
    })
    _autocomplete.addListener('place_changed', () => {
      const result = _autocomplete.getPlace()
      if (result.geometry) {
        const loc = result.geometry.location
        setLocation({
          lat: loc.lat(),
          lng: loc.lng(),
          gps: false,
          geocode: _createReverseGeocode({
            lat: loc.lat(),
            lng: loc.lng()
          })
        })
        _map.setCenter(loc)
      } else {
        _geocoder.geocode({ address: result.name }, (res, status) => {
          if (status === 'OK') {
            const loc = res[0].geometry.location
            setLocation({
              lat: loc.lat(),
              lng: loc.lng(),
              gps: false,
              geocode: () =>
                Promise.resolve({
                  ...parseGeocodeResult(res),
                  placesResult: res?.[0]
                })
            })
            _map.setCenter(loc)
          }
        })
      }
    })
    setMapRefs({
      gmap: _map,
      maps: _maps,
      geocoder: _geocoder,
      circle: _circle,
      autocomplete: _autocomplete,
      createReverseGeocode: _createReverseGeocode,
      controlDiv: createDiv
    })
  }

  useEffect(
    () => {
      if (circle) {
        circle.setCenter(pick(['lat', 'lng'], location))
        circle.setRadius(radius * 1000)
      }
      if (location.lat && stores) {
        const toMeters = {
          lat: meterPerDeg,
          lng: meterPerDeg * Math.cos((Math.PI * location.lat) / 180)
        }
        setResults(
          compose(
            filter(store =>
              all(identity)(
                unnest(
                  values(
                    mapObjIndexed(
                      (groupObj, group) =>
                        values(
                          mapObjIndexed(
                            (bool, key) => !bool || store?.[group]?.[key],
                            groupObj
                          )
                        ),
                      filterObj
                    )
                  )
                )
              )
            ),
            filter(o(gt(radius * radius * 1000000), prop('distance'))),
            map(
              converge(assoc('distance'), [
                compose(
                  sum,
                  map(x => x * x), // converge(multiply, [identity, identity])),
                  values,
                  mergeWith(multiply, toMeters),
                  mergeWith(subtract, pick(['lat', 'lng'], location)),
                  prop('location')
                ),
                identity
              ])
            )
          )(stores)
        )
      }
    },
    [location, stores, radius, filterObj]
  )

  useEffect(
    () => {
      async function loadStores () {
        const response = await fetch(
          data.database || '/rt-db/locator-stores.json'
        )
        const result = await response.json()
        setStores(
          compose(
            map(
              evolve({
                location: applySpec({ lat: prop('lat'), lng: prop('lon') })
              })
            ),
            unless(
              always(isNil(data.countryFilter) || isEmpty(data.countryFilter)),
              filter(o(contains(__, data.countryFilter), prop('country')))
            ),
            filter(both(prop('location'), prop('dealer'))),
            map(apply(assoc('id'))),
            toPairs
          )(result)
        )
      }
      loadStores()
    },
    [data.database]
  )

  const updateRadius = useCallback(
    e => setRadius(Math.min(e.target.value, data.maxRadius)),
    []
  )

  return (
    <>
      <Dialog onClose={openPhoneDialog(false)} open={!!dialogPhoneNumber}>
        <DialogTitle onClose={openPhoneDialog(false)}>
          {data.phoneLabel}
        </DialogTitle>
        <DialogContent>{dialogPhoneNumber}</DialogContent>
        <DialogActions>
          <a href={`tel:${dialogPhoneNumber}`}>
            <Button>{data.callLabel}</Button>
          </a>
          <Button onClick={openPhoneDialog(false)}>{data.closeLabel}</Button>
        </DialogActions>
      </Dialog>
      <Modal
        onRequestClose={closeModal}
        open={!!modalProps}
        slug={data.action?.story?.url
          .split('/')
          .filter(x => x !== '')
          .map(x => x.replace(/^.._..$/, l => l.replace('_', '-')))
          .join('/')}
        props={modalProps || {}}
      />
      <Background>
        {controlDiv
          && ReactDOM.createPortal(
            <LocationButton onClick={getLocation} title="Your Location">
              {location.gps ? <GpsFixed /> : <GpsNotFixed />}
            </LocationButton>,
            controlDiv
          )}
        <Container spacing={1} container>
          <Item lg={5} xs={12} item>
            <Content justify="center">
              {data.roofline && <div className="roofline">{data.roofline}</div>}
              <h1>{data.title}</h1>
              <div className="subline">{data.subline}</div>
            </Content>
          </Item>
          <Item lg={7} xs={12} item>
            <Content justify="space-evenly" align="flex-end">
              <TextField
                inputRef={searchInput}
                fullWidth
                placeholder={data.searchPlaceholder}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { backgroundColor: 'white' }
                }}
              />
              <Inputs>
                <div>
                  {unnest(
                    values(
                      mapObjIndexed(
                        (obj, group) => (
                          <div>
                            {values(
                              mapObjIndexed(
                                (label, key) =>
                                  label && (
                                    <FormControlLabel
                                      key={key}
                                      control={(
<Checkbox
                                          onChange={updateFilter([group, key])}
                                          checked={path(
                                            [group, key],
                                            filterObj
                                          )}
                                          id={key}
                                        />
)}
                                      label={label}
                                    />
                                  ),
                                obj
                              )
                            )}
                          </div>
                        ),
                        {
                          products: {
                            garageDoors: data.rgdTranslation,
                            industry: data.idTranslation,
                            doors: data.doorsTranslation
                          },
                          services: {
                            consultation: data.consultationTranslation,
                            assembly: data.assemblyTranslation,
                            service: data.serviceTranslation
                          }
                        }
                      )
                    )
                  )}
                </div>
                <RadiusContainer>
                  <StyledInputLabel htmlFor="radius">
                    {data.radiusLabel}
                  </StyledInputLabel>
                  <TextField
                    id="radius"
                    type="number"
                    value={radius}
                    onChange={updateRadius}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">km</InputAdornment>
                      ),
                      style: { textAlign: 'right', backgroundColor: 'white' }
                    }}
                  />
                </RadiusContainer>
              </Inputs>
            </Content>
          </Item>
          <Item xs={12} item>
            <MapContainer>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyBB7vcZ9gtYecNDxH22BJwthLo7e45jXF8',
                  libraries: 'places'
                }}
                defaultCenter={{
                  lat: Number(data.defaultLat),
                  lng: Number(data.defaultLng)
                }}
                defaultZoom={7}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={initMap}
              >
                {/* map(
                store => (
                  <Marker {...store.location} />
                ),
                values(stores || [])
              ) */}
              </GoogleMapReact>
            </MapContainer>
          </Item>
          {map(
            result => (
              <Item key={result.id} xs={12} item>
                <Content
                  media={device =>
                    (device === 'mobile'
                      ? 'flex-direction:column; align-items: center; height:auto;'
                      : 'flex-direction:row;')
                  }
                >
                  <Card>
                    <p>{result.name}</p>
                    <p>
                      {result.address?.street} {result.address?.nr}
                    </p>
                    <p>
                      {result.address?.postCode} {result.address?.city}
                    </p>
                    <p>
                      <a href={`mailto:${result.dealer?.email}`}>
                        {data.emailLabel}: {result.dealer?.email}
                      </a>
                    </p>
                    <p>
                      <Link>
                        <Button onClick={openModal(result)}>
                          {data.requestQuoteLabel}
                        </Button>
                      </Link>
                      <p>
                        <Button
                          disabled={!result.dealer?.phone}
                          onClick={openPhoneDialog(result)}
                        >
                          {data.phoneLabel}
                        </Button>
                      </p>
                      {(Math.sqrt(result.distance) / 1000).toFixed(1)} km
                    </p>
                  </Card>
                  <Table>
                    <tbody>
                      <Row>
                        <Col />
                        <Col>{data.consultationTranslation}</Col>
                        <Col>{data.assemblyTranslation}</Col>
                        <Col>{data.serviceTranslation}</Col>
                      </Row>
                      {values(
                        mapObjIndexed(
                          (value, row) =>
                            value && (
                              <Row>
                                <Col>{value}</Col>
                                {map(
                                  col => (
                                    <Col>
                                      {both(
                                        path(['products', row]),
                                        path(['services', col])
                                      )(result) && <Check />}
                                    </Col>
                                  ),
                                  ['consultation', 'assembly', 'service']
                                )}
                              </Row>
                            ),
                          {
                            garageDoors: data.rgdTranslation,
                            industry: data.idTranslation,
                            doors: data.doorsTranslation
                          }
                        )
                      )}
                    </tbody>
                  </Table>
                </Content>
              </Item>
            ),
            sortBy(prop('distance'), results)
          )}
          {data.submitWithoutVendor?.length === 1 && (
            <Item
              item
              xs={12}
              style={{
                padding: 0,
                margin: '4px',
                position: 'relative',
                height: '230px'
              }}
            >
              <StoryblokTileCallToAction
                data={data.submitWithoutVendor[0]}
                dimensions={() => ({ ratio: 1.1 })}
                linkProps={{
                  onClick: openModal()
                  /* link: data.action,
                  modalProps: {
                    formData: { ...historyContext },
                    prefetch: location.geocode
                  } */
                }}
              />
            </Item>
          )}
        </Container>
      </Background>
    </>
  )
}
